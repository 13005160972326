<template>
  <div class="message-template">
    <div
      ref="messageTemplateContent"
      class="message-template__content-wrapper"
      :class="{ 'message-template__content-wrapper--with-scroll': hasScroll }"
    >
      <slot name="header-content"></slot>
      <div class="message-template__header section flex--space-between--center">
        <div class="message-template__header__template">
          <div class="section-title label-text">
            {{ $t('dashboard.label.marketingCampaignTitle') }}
          </div>
          <dropdown-select
            :placeholder="placeholder"
            :current-value="currentMarketingCampaign"
            :select-values="marketingCampaigns"
            :is-auto-positioned="false"
            label="title"
            @select="selectMarketingCampaign"
          />
        </div>
        <div class="message-template__header__language">
          <language-dropdown-input :language="currentLanguage" @language-select="selectLanguage" />
        </div>
      </div>
      <div v-if="isEmailTemplate" class="message-template__subject section">
        <div class="section-title label-text">{{ $t('dashboard.label.subject') }}</div>
        <input
          v-model="templateData.subject"
          v-validate="'required'"
          name="subject"
          class="subject-input rounded-input input-focus-within"
          :class="{ 'error-input-border': hasSubjectError }"
          @input="onSubjectInput"
          @change="updateTemplateText"
        />
      </div>

      <component
        :is="templateComponentName"
        class="mb20"
        :template-data="templateData"
        :template-errors="currentTemplateErrors"
        :fields-max-length="fieldsMaxLength"
        :template-type="type"
        @field-input="updateTemplateFieldHandler"
        @field-change="updateTemplateText"
      />

      <div v-if="isSmsTemplate" class="message-template__limitations mb20">
        {{ $t('dashboard.label.charactersLeft', [remainingCharacters]) }},
        {{ $t('dashboard.action.smsCounter', [smsCount, 5]) }}
      </div>
    </div>
    <div v-if="isFooterVisible" class="divider message-template__footer-divider otherXS"></div>
    <div v-if="isFooterVisible" class="message-template__footer flex--space-between--center">
      <div class="message-template__footer__send-button flex--align-center">
        <slot name="send-button" :sendTemplate="sendTemplate"></slot>
        <span v-if="hint" class="message-template__footer__hint">
          {{ hint }}
        </span>
      </div>
      <button
        class="message-template__footer__update-button rounded-button-gray"
        :disabled="isUpdateButtonDisabled"
        @click="updateMarketingTemplate"
      >
        {{ $t('dashboard.action.updateTemplate') }}
      </button>
    </div>
    <dashboard-notification />
  </div>
</template>

<script>
import Splitter from 'split-sms';
import PerfectScrollbar from 'perfect-scrollbar';

import { mapActions, mapGetters } from 'vuex';
import { isEmpty, first, get } from 'lodash';

import DropdownSelect from '@/modules/dashboard/components/common/DropdownSelect';
import LanguageDropdownInput from '@/modules/dashboard/components/profile/LanguageDropdownInput';
import DashboardNotification from '@/modules/dashboard/components/dashboard/common/DashboardNotification';
import DefaultMarketingMessageTemplate from '@/modules/dashboard/components/message-templates/marketing/DefaultMarketingMessageTemplate';
import EmailMarketingMessageTemplate from '@/modules/dashboard/components/message-templates/marketing/EmailMarketingMessageTemplate';

import updateTemplateFieldMixin from '@/modules/dashboard/components/mixins/updateTemplateFieldMixin';

import {
  getMarketingCampaignTemplate,
  getTemplateGroupsByTypes,
  updateMarketingCampaignTemplate
} from '@/modules/dashboard/api';

import {
  getNonNegativeNumber,
  getStringLength,
  buildMessageTemplateText,
  isMobileDevice,
  getTemplateErrorsFromVeeValidationErrors
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import { ACCOUNT_NOTIFICATION_DISPLAYING_DURATION, DEFAULT_LOCALE } from '@/constants';

import {
  MESSAGE_TEMPLATE_TYPES,
  NO_TEMPLATE,
  SELECT_A_TEMPLATE,
  SMS_TEMPLATE_CONST_SYMBOLS,
  SMS_MAX_LENGTH,
  MESSAGE_MAX_LENGTH,
  TEMPLATE_GROUP_TYPES,
  MARKETING_CAMPAIGN_TEMPLATE_NAMES,
  MARKETING_CAMPAIGN_TITLES_KEY
} from '@/modules/dashboard/api/constants';

const TEMPLATE_COUNTABLE_FIELDS = {
  greeting: '',
  bodyPartOne: '',
  bodyPartTwo: '',
  bodyPartThree: '',
  signature: ''
};

const CURLY_BRACES_COUNTER_VALUE = 2;

export default {
  name: 'MessageTemplate',
  components: {
    EmailMarketingMessageTemplate,
    DefaultMarketingMessageTemplate,
    DashboardNotification,
    LanguageDropdownInput,
    DropdownSelect
  },
  inject: ['$validator'],
  mixins: [updateTemplateFieldMixin],
  props: {
    hint: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    initialLanguage: {
      type: String,
      default: ''
    },
    isFooterVisible: {
      type: Boolean,
      default: true
    },
    hasScroll: {
      type: Boolean,
      default: true
    },
    shouldUseExternalLanguage: {
      type: Boolean,
      default: false
    },
    externalLanguage: {
      type: String,
      default: DEFAULT_LOCALE
    },
    templateErrors: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      marketingCampaigns: [],
      currentMarketingCampaign: null,
      messageTemplateScrollbar: null,
      language: null,
      placeholder: SELECT_A_TEMPLATE,
      templateData: {
        subject: '',
        linkName: '',
        ...TEMPLATE_COUNTABLE_FIELDS
      },
      lengthData: {
        ...TEMPLATE_COUNTABLE_FIELDS
      },
      currentTemplateErrors: {}
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      userLanguage: rootTypes.getters.GET_USER_LANGUAGE,
      treatmentId: dashTypes.getters.SUMMARY_TREATMENT_ID,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID
    }),
    currentLanguage() {
      return this.shouldUseExternalLanguage ? this.externalLanguage : this.language;
    },
    isEmailTemplate() {
      return this.type === MESSAGE_TEMPLATE_TYPES.EMAIL;
    },
    isSmsTemplate() {
      return this.type === MESSAGE_TEMPLATE_TYPES.SMS;
    },
    templateComponentName() {
      return this.isEmailTemplate
        ? MARKETING_CAMPAIGN_TEMPLATE_NAMES.EMAIL_TEMPLATE
        : MARKETING_CAMPAIGN_TEMPLATE_NAMES.DEFAULT;
    },
    isUpdateButtonDisabled() {
      return !this.currentMarketingCampaign;
    },
    templateMessageLinkNameText() {
      return get(this.templateData, 'linkName') || '';
    },
    footerAndConstSymbolsLength() {
      return this.templateMessageLinkNameText.length + SMS_TEMPLATE_CONST_SYMBOLS;
    },
    editableTextMaxLength() {
      return this.smsMaxLength - this.footerAndConstSymbolsLength;
    },
    greetingMaxLength() {
      return this.getFieldLength(this.greetingLength + this.messageSymbolsLeft);
    },
    bodyPartOneMaxLength() {
      return this.getFieldLength(this.bodyPartOneLength + this.messageSymbolsLeft);
    },
    bodyPartTwoMaxLength() {
      return this.getFieldLength(this.bodyPartTwoLength + this.messageSymbolsLeft);
    },
    bodyPartThreeMaxLength() {
      return this.getFieldLength(this.bodyPartThreeLength + this.messageSymbolsLeft);
    },
    signatureMaxLength() {
      return this.getFieldLength(this.signatureLength + this.messageSymbolsLeft);
    },
    fieldsMaxLength() {
      return {
        greeting: this.greetingMaxLength,
        bodyPartOne: this.bodyPartOneMaxLength,
        bodyPartTwo: this.bodyPartTwoMaxLength,
        bodyPartThree: this.bodyPartThreeMaxLength,
        signature: this.signatureMaxLength
      };
    },
    greetingLength() {
      return getStringLength(this.lengthData.greeting);
    },
    bodyPartOneLength() {
      return getStringLength(this.lengthData.bodyPartOne);
    },
    bodyPartTwoLength() {
      return getStringLength(this.lengthData.bodyPartTwo);
    },
    bodyPartThreeLength() {
      return getStringLength(this.lengthData.bodyPartThree);
    },
    signatureLength() {
      return getStringLength(this.lengthData.signature);
    },
    messageSymbolsLength() {
      return (
        this.greetingLength +
        this.bodyPartOneLength +
        this.bodyPartTwoLength +
        this.bodyPartThreeLength +
        this.signatureLength +
        CURLY_BRACES_COUNTER_VALUE
      );
    },
    messageSymbolsLeft() {
      return getNonNegativeNumber(this.editableTextMaxLength - this.messageSymbolsLength);
    },
    smsMaxLength() {
      return SMS_MAX_LENGTH[this.marketingCampaignSmsCounter.characterSet];
    },
    marketingCampaignText() {
      return this.buildTemplateTextWithField();
    },
    marketingCampaignSmsCounter() {
      return Splitter.split(this.marketingCampaignText);
    },
    remainingCharacters() {
      return this.marketingCampaignSmsCounter.remainingInPart;
    },
    smsCount() {
      return get(this.marketingCampaignSmsCounter, 'parts.length');
    },
    hasSubjectError() {
      return this.currentTemplateErrors.subject;
    }
  },
  watch: {
    externalLanguage(newLanguage) {
      if (this.shouldUseExternalLanguage && newLanguage) {
        this.updateTemplateOnLanguageChange(newLanguage);
      }
    },
    // TODO change logic for reseting templateErrors https://jira.andersenlab.com/browse/UNK-4493
    templateErrors(errors) {
      this.currentTemplateErrors = errors;
    }
  },
  async mounted() {
    const { messageTemplateContent } = this.$refs;

    if (!isMobileDevice() && messageTemplateContent && this.hasScroll) {
      this.messageTemplateScrollbar = new PerfectScrollbar(messageTemplateContent, {
        wheelPropagation: false,
        suppressScrollX: true
      });
    }

    if (!this.shouldUseExternalLanguage) {
      this.language = this.initialLanguage || this.userLanguage;
    }

    this.setLoading(true);

    const { data } = await getTemplateGroupsByTypes({
      userId: this.userId,
      types: [TEMPLATE_GROUP_TYPES.SKINXS_CAMPAIGN, TEMPLATE_GROUP_TYPES.CUSTOM_CAMPAIGN]
    });
    this.marketingCampaigns = data;

    this.marketingCampaigns = this.marketingCampaigns.map(campaign => ({
      ...campaign,
      title: this.getMarketingCampaignTitle(campaign.title)
    }));

    if (isEmpty(this.marketingCampaigns)) {
      this.placeholder = NO_TEMPLATE;
      this.setLoading(false);

      return;
    }

    this.currentMarketingCampaign = first(this.marketingCampaigns);
    await this.getMarketingTemplate(this.currentLanguage);
    this.updateTemplateText();

    this.setLoading(false);
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    getFieldLength(smsLength) {
      return this.isSmsTemplate ? getNonNegativeNumber(smsLength) : MESSAGE_MAX_LENGTH;
    },
    buildTemplateTextWithField({ fieldName, fieldValue } = {}) {
      const textObject = {
        ...this.lengthData,
        linkName: this.templateMessageLinkNameText
      };

      if (fieldName) {
        textObject[fieldName] = fieldValue;
      }

      return buildMessageTemplateText(textObject);
    },
    updateCurrentTemplateField({ fieldName, value }) {
      this.templateData[fieldName] = value;
      this.currentTemplateErrors[fieldName] = false;
      this.updateTemplateText();
    },
    async selectMarketingCampaign(campaign) {
      this.currentMarketingCampaign = campaign;

      await this.getMarketingTemplate(this.currentLanguage);
      this.updateTemplateText();
    },
    async selectLanguage(language) {
      if (this.shouldUseExternalLanguage) {
        this.$emit('select-language', language);

        return;
      }

      this.language = language;
      this.updateTemplateOnLanguageChange(language);
    },
    async updateTemplateOnLanguageChange(language) {
      await this.getMarketingTemplate(language);
      this.updateTemplateText();
    },
    async getMarketingTemplate(language) {
      const { data } = await getMarketingCampaignTemplate({
        userId: this.userId,
        groupId: this.currentMarketingCampaign.id,
        wayOfSend: this.type,
        language
      });
      this.templateData = data;

      this.updateTemplateLengthData(this.templateData);

      this.$emit('get-template', this.templateData);
    },
    updateTemplateLengthData({ bodyPartOne, signature }) {
      this.lengthData.bodyPartOne = bodyPartOne;
      this.lengthData.signature = signature;
    },
    updateTemplateText() {
      this.$emit('update-template-text', this.templateData);
    },
    async updateMarketingTemplate() {
      if (!(await this.$validator.validateAll())) {
        this.currentTemplateErrors = getTemplateErrorsFromVeeValidationErrors(this.errors);

        return;
      }
      const { data } = await updateMarketingCampaignTemplate({
        userId: this.userId,
        templateData: this.templateData
      });
      this.templateData = data;

      this.$notify({
        group: 'dashboard-notification',
        title: this.$t('dashboard.label.templateUpdated'),
        duration: ACCOUNT_NOTIFICATION_DISPLAYING_DURATION
      });
    },
    sendTemplate() {
      if (this.currentMarketingCampaign) {
        this.$emit('send-template', this.templateData);
      }
    },
    updateTemplateFieldHandler(event) {
      this.currentTemplateErrors[event.fieldName] = false;
      this.updateTemplateField(event);
    },
    onSubjectInput() {
      this.currentTemplateErrors.subject = false;
    },
    getMarketingCampaignTitle(title) {
      return MARKETING_CAMPAIGN_TITLES_KEY[title]
        ? this.$t(MARKETING_CAMPAIGN_TITLES_KEY[title])
        : title;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/common/text';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/perfect-scrollbar-styles.css';
@import '../../../../../assets/css/common/errors';

.message-template {
  &__content-wrapper {
    padding: 0 25px;

    &--with-scroll {
      position: relative;
      overflow: hidden;
      height: 550px;
    }
  }

  &__header {
    &__template {
      width: 69%;
    }

    &__language {
      width: 29%;
      font-size: 13px;
      line-height: 19px;
    }
  }

  &__message {
    &__default-ending {
      margin-top: 10px;
      font-size: 15px;
      color: $dim-grey-color;
      cursor: default;
    }
  }

  &__limitations {
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    color: $grey-color;
    font-style: italic;

    margin-top: 5px;
  }

  &__footer {
    height: 70px;
    padding: 0 25px;

    &__hint {
      font-size: 12px;
      line-height: 14px;
      margin-left: 20px;
      font-style: italic;

      color: $hint-text-color;
    }

    &__update-button {
      height: 36px;

      &:disabled {
        cursor: default;
      }
    }
  }
}

.section {
  margin-bottom: 15px;
}

.section-title {
  margin-bottom: 10px;
}

.rounded-input {
  width: 100%;
  min-height: 36px;
}

@media (max-width: 767px) {
  .message-template {
    &__content-wrapper {
      padding: 0 20px;

      &--with-scroll {
        overflow: auto;
        height: auto;
      }
    }

    &__header {
      flex-direction: column;

      &__template,
      &__language {
        width: 100%;
        font-size: 12px;
        line-height: 18px;
      }

      &__template {
        margin-bottom: 10px;
      }
    }

    &__message {
      &__default-ending {
        word-break: break-word;
        font-size: 16px;
      }
    }

    .subject-input,
    .message-input,
    .default-ending-text,
    .signature-input {
      font-size: 16px;
      line-height: 15px;
    }

    &__footer {
      flex-direction: column;
      padding: 0 20px 35px;
      height: auto;

      &__send-button {
        flex-direction: column;

        &__text {
          margin-bottom: 15px;
        }
      }

      &__update-button {
        height: 32px;
        margin-top: 25px;

        font-size: 16px;
        line-height: 19px;
      }

      &__hint {
        margin-top: 15px;
      }
    }
  }

  .scrollable-block {
    max-height: none;
  }

  .section {
    margin-bottom: 10px;
  }

  .section-title {
    margin-bottom: 4px;
  }
}

@media (min-width: 768px) and (max-height: 750px) {
  .message-template__content-wrapper {
    &--with-scroll {
      height: 420px;
    }
  }
}
</style>
