<template>
  <div class="facebook-share">
    <modal-header-with-previous
      class="modal-header-with-previous"
      current-modal-name="share-with-facebook-modal"
      previous-modal-name="invite-patient-modal"
    >
      <span slot="header-title" class="modal-header-with-previous__title">
        {{ $t('dashboard.label.shareWithFacebook') }}
      </span>
    </modal-header-with-previous>
    <main class="facebook-share__message-template">
      <message-template
        :hint="$t('dashboard.label.copyTextToClipboard')"
        type="FACEBOOK"
        @send-template="sendFacebookTemplate"
      >
        <div slot="send-button" slot-scope="{ sendTemplate }">
          <social-sharing :url="questionnaireLink" inline-template>
            <div>
              <network network="facebook">
                <button class="rounded-button-facebook" @click="sendTemplate">
                  <span class="fab fa-facebook-square mr5"></span>
                  <span>
                    {{ $t('dashboard.action.shareWithFacebook') }}
                  </span>
                </button>
              </network>
            </div>
          </social-sharing>
        </div>
      </message-template>
    </main>
    <dashboard-notification />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import rootTypes from '@/store/types';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import MessageTemplate from '@/modules/dashboard/components/dashboard/common/MessageTemplate';
import DashboardNotification from '@/modules/dashboard/components/dashboard/common/DashboardNotification';

import { copyToClipboard, buildMessageTemplateText } from '@/modules/dashboard/utils';

import { ACCOUNT_NOTIFICATION_DISPLAYING_DURATION } from '@/constants';

export default {
  name: 'ShareWithFacebook',
  components: { DashboardNotification, MessageTemplate, ModalHeaderWithPrevious },
  data() {
    return {
      isCopiedToClipboard: false
    };
  },
  computed: {
    ...mapGetters({
      questionnaireLink: rootTypes.getters.DOCTOR_QUESTIONNAIRE_LINK
    })
  },
  methods: {
    sendFacebookTemplate(marketingTemplate) {
      const messageTemplate = buildMessageTemplateText(marketingTemplate);

      copyToClipboard(messageTemplate);

      this.$notify({
        group: 'dashboard-notification',
        title: 'Message is copied to clipboard',
        duration: ACCOUNT_NOTIFICATION_DISPLAYING_DURATION
      });

      this.$modal.hide('share-with-facebook-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/scss/common/text';
@import '../../../../../../assets/scss/modals/modal-header-with-previous-shared';
@import '../../../../../../assets/scss/invite-patients/invite-patient-shared';

/deep/ .rounded-button-facebook {
  border-radius: 50px;
  background: $links-color;
  border: transparent;
  color: $white;
  padding: 10px 20px;
  text-align: center;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 17px;
  transition: all 0.1s;

  &:hover {
    background: $light-blue-gray;
  }
}

.facebook-share {
  font-size: 15px;
  line-height: 17px;
  color: $main-title-color;
}

@media (max-width: 767px) {
  .facebook-share {
    font-size: 13px;
    line-height: 15px;
    background-color: $white;
    min-height: 100vh;
  }

  /deep/ .rounded-button-facebook {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
