<template>
  <div class="default-marketing-template">
    <default-template-message class="mb15">
      <template-text-area
        ref="messageTextarea"
        :value="templateData.bodyPartOne"
        :maxlength="fieldsMaxLength.bodyPartOne"
        field-name="bodyPartOne"
        @input="
          onMarketingMessageFieldInput({
            ...$event,
            isTextarea: true,
            refName: 'messageTextarea',
            isSmsCampaign: isSmsMessageTemplate
          })
        "
        @change="updateCurrentMarketingTemplateField"
      />
      <div class="message__footer">{{ templateData.linkName }}</div>
    </default-template-message>

    <div class="template__signature">
      <div class="template__label">{{ $t('dashboard.label.signature') }}</div>
      <input
        ref="signatureInput"
        type="text"
        class="rounded-input template__input input-focus-within"
        :value="templateData.signature"
        :maxlength="fieldsMaxLength.signature"
        @input="
          onMarketingMessageFieldInput({
            fieldName: 'signature',
            event: $event,
            refName: 'signatureInput',
            isSmsCampaign: isSmsMessageTemplate
          })
        "
        @change="
          updateCurrentMarketingTemplateField({
            fieldName: 'signature',
            value: $event.target.value
          })
        "
      />
    </div>
  </div>
</template>

<script>
import TemplateTextArea from '@/modules/dashboard/components/message-templates/TemplateTextArea';
import DefaultTemplateMessage from '@/modules/dashboard/components/message-templates/common/DefaultTemplateMessage';

import { MESSAGE_TEMPLATE_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'DefaultMarketingMessageTemplate',
  components: { TemplateTextArea, DefaultTemplateMessage },
  props: {
    templateData: {
      type: Object,
      required: true
    },
    fieldsMaxLength: {
      type: Object,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  computed: {
    isSmsMessageTemplate() {
      return this.templateType === MESSAGE_TEMPLATE_TYPES.SMS;
    }
  },
  methods: {
    onMarketingMessageFieldInput(eventValue) {
      this.$emit('field-input', eventValue);
    },
    updateCurrentMarketingTemplateField(eventValue) {
      this.$emit('field-change', eventValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/message-templates/message-templates';
</style>
