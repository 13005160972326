var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"default-marketing-template"},[_c('default-template-message',{staticClass:"mb15"},[_c('template-text-area',{ref:"messageTextarea",attrs:{"value":_vm.templateData.bodyPartOne,"maxlength":_vm.fieldsMaxLength.bodyPartOne,"field-name":"bodyPartOne"},on:{"input":function($event){return _vm.onMarketingMessageFieldInput(Object.assign({}, $event,
          {isTextarea: true,
          refName: 'messageTextarea',
          isSmsCampaign: _vm.isSmsMessageTemplate}))},"change":_vm.updateCurrentMarketingTemplateField}}),_c('div',{staticClass:"message__footer"},[_vm._v(_vm._s(_vm.templateData.linkName))])],1),_c('div',{staticClass:"template__signature"},[_c('div',{staticClass:"template__label"},[_vm._v(_vm._s(_vm.$t('dashboard.label.signature')))]),_c('input',{ref:"signatureInput",staticClass:"rounded-input template__input input-focus-within",attrs:{"type":"text","maxlength":_vm.fieldsMaxLength.signature},domProps:{"value":_vm.templateData.signature},on:{"input":function($event){return _vm.onMarketingMessageFieldInput({
          fieldName: 'signature',
          event: $event,
          refName: 'signatureInput',
          isSmsCampaign: _vm.isSmsMessageTemplate
        })},"change":function($event){return _vm.updateCurrentMarketingTemplateField({
          fieldName: 'signature',
          value: $event.target.value
        })}}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }